<template>
  <div class="pc-wrap">
    <h1>This is an about page</h1>
    <Sidebar />
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from '@/components/Sidebar'

export default {
    name: 'About',
    components: {
        Sidebar
    }
}
</script>